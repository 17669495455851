import React from 'react'
import Layout from '../layouts/index'

const SideProject = () => (
  <Layout>
    <div className="container">
      <section className="section section-title">
        <div className="container">
          <h1 className="title has-text-centered">
            Side Project ... Comming Soon.
          </h1>
        </div>
        <hr className="section-title__gap" />
      </section>

      {/* <section className="section portfolio-content">
      <div className="container">
        <div className="columns features is-multiline">
          <PortfolioCard
            portfolioFilter="Print"
            portfolioTitle="Get Lost in Thailand"
            portfolioDescription="Print Marketing"
            portfolioImage={LogoLight}
          />
          <PortfolioCard
            portfolioFilter="Print"
            portfolioTitle="Get Lost in Thailand"
            portfolioDescription="Print Marketing"
            portfolioImage={LogoLight}
          />
          <PortfolioCard
            portfolioFilter="Print"
            portfolioTitle="Get Lost in Thailand"
            portfolioDescription="Print Marketing"
            portfolioImage={LogoLight}
          />
          <PortfolioCard
            portfolioFilter="Print"
            portfolioTitle="Get Lost in Thailand"
            portfolioDescription="Print Marketing"
            portfolioImage={LogoLight}
          />
          <PortfolioCard
            portfolioFilter="Print"
            portfolioTitle="Get Lost in Thailand"
            portfolioDescription="Print Marketing"
            portfolioImage={LogoLight}
          />
        </div>
      </div>
    </section> */}
    </div>
  </Layout>
)

export default SideProject
